<template>
 <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
  <div class="py-5 align-middle inline-block min-w-full sm:px-6 lg:px-10">
   <div class="lg:flex lg:items-center lg:justify-between">
    <div class="min-w-0 flex-1">
     <h2
      class="sm:ml-5 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-4xl sm:tracking-tight"
     >
      {{ title }}
     </h2>

     <div
      v-if="hasMetaData"
      class="mt-1 flex flex-col sm:ml-5 sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6"
     >
      <div v-if="meta?.active" class="mt-2 flex items-center text-sm text-gray-500">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="mr-1 h-5 w-5 flex-shrink-0"
       >
        <path
         fill-rule="evenodd"
         d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
         clip-rule="evenodd"
        />
       </svg>

       {{ meta?.active ? $t("active") : $t("nonactive") }}
      </div>
      <!--   <div class="mt-2 flex items-center text-sm text-gray-500">
          <svg
           class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
           viewBox="0 0 20 20"
           fill="currentColor"
           aria-hidden="true"
          >
           <path
            fill-rule="evenodd"
            d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
            clip-rule="evenodd"
           />
          </svg>
          Remote
         </div>
         <div class="mt-2 flex items-center text-sm text-gray-500">
          <svg
           class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
           viewBox="0 0 20 20"
           fill="currentColor"
           aria-hidden="true"
          >
           <path
            d="M10.75 10.818v2.614A3.13 3.13 0 0011.888 13c.482-.315.612-.648.612-.875 0-.227-.13-.56-.612-.875a3.13 3.13 0 00-1.138-.432zM8.33 8.62c.053.055.115.11.184.164.208.16.46.284.736.363V6.603a2.45 2.45 0 00-.35.13c-.14.065-.27.143-.386.233-.377.292-.514.627-.514.909 0 .184.058.39.202.592.037.051.08.102.128.152z"
           />
           <path
            fill-rule="evenodd"
            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-6a.75.75 0 01.75.75v.316a3.78 3.78 0 011.653.713c.426.33.744.74.925 1.2a.75.75 0 01-1.395.55 1.35 1.35 0 00-.447-.563 2.187 2.187 0 00-.736-.363V9.3c.698.093 1.383.32 1.959.696.787.514 1.29 1.27 1.29 2.13 0 .86-.504 1.616-1.29 2.13-.576.377-1.261.603-1.96.696v.299a.75.75 0 11-1.5 0v-.3c-.697-.092-1.382-.318-1.958-.695-.482-.315-.857-.717-1.078-1.188a.75.75 0 111.359-.636c.08.173.245.376.54.569.313.205.706.353 1.138.432v-2.748a3.782 3.782 0 01-1.653-.713C6.9 9.433 6.5 8.681 6.5 7.875c0-.805.4-1.558 1.097-2.096a3.78 3.78 0 011.653-.713V4.75A.75.75 0 0110 4z"
            clip-rule="evenodd"
           />
          </svg>
          $120k &ndash; $140k
         </div> -->
      <div v-if="meta?.created_at" class="mt-2 flex items-center text-sm text-gray-500">
       <svg
        class="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
       >
        <path
         fill-rule="evenodd"
         d="M5.75 2a.75.75 0 01.75.75V4h7V2.75a.75.75 0 011.5 0V4h.25A2.75 2.75 0 0118 6.75v8.5A2.75 2.75 0 0115.25 18H4.75A2.75 2.75 0 012 15.25v-8.5A2.75 2.75 0 014.75 4H5V2.75A.75.75 0 015.75 2zm-1 5.5c-.69 0-1.25.56-1.25 1.25v6.5c0 .69.56 1.25 1.25 1.25h10.5c.69 0 1.25-.56 1.25-1.25v-6.5c0-.69-.56-1.25-1.25-1.25H4.75z"
         clip-rule="evenodd"
        />
       </svg>
       {{ $t(createdAt, { date: $d(meta?.created_at, "shortText") }) }}
      </div>
     </div>
    </div>
    <div v-if="hasSearch">
     <div class="relative rounded-md shadow-sm">
      <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
       <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
       id="search"
       name="search"
       type="search"
       v-model="search"
       @input="$emit('update:search', $event.target.value)"
       class="block w-full rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-700 sm:text-sm sm:leading-6"
       :placeholder="$t('search')"
      />
     </div>
    </div>
    <div v-if="hasActionButton" class="mt-5 flex lg:mt-0 lg:ml-4">
     <span v-show="firstButtonTitle" class="hidden sm:block">
      <button
       type="button"
       class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
       @click.prevent="$emit('import')"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
       >
        <path
         d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z"
        />
        <path
         d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z"
        />
       </svg>

       {{ firstButtonTitle }}
      </button>
     </span>

     <span v-show="secondButtonTitle" class="ml-3 hidden sm:block">
      <button
       type="button"
       class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
       @click.prevent="$emit('export')"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="-ml-0.5 mr-1.5 h-5 w-5 text-gray-400"
       >
        <path
         fill-rule="evenodd"
         d="M4.25 5.5a.75.75 0 00-.75.75v8.5c0 .414.336.75.75.75h8.5a.75.75 0 00.75-.75v-4a.75.75 0 011.5 0v4A2.25 2.25 0 0112.75 17h-8.5A2.25 2.25 0 012 14.75v-8.5A2.25 2.25 0 014.25 4h5a.75.75 0 010 1.5h-5z"
         clip-rule="evenodd"
        />
        <path
         fill-rule="evenodd"
         d="M6.194 12.753a.75.75 0 001.06.053L16.5 4.44v2.81a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.553l-9.056 8.194a.75.75 0 00-.053 1.06z"
         clip-rule="evenodd"
        />
       </svg>

       {{ secondButtonTitle }}
      </button>
     </span>

     <span v-show="lastButtonTitle" class="sm:ml-3">
      <button
       @click.prevent="$emit('delete')"
       type="button"
       class="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-red-300 hover:text-red-500 hover:bg-red-50"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="-ml-0.5 mr-1.5 h-5 w-5 text-red-500"
       >
        <path
         fill-rule="evenodd"
         d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
         clip-rule="evenodd"
        />
       </svg>
       {{ lastButtonTitle }}
      </button>
     </span>

     <span v-show="hasActionButton && hasPermission" class="sm:ml-3">
      <button
       @click.prevent="$emit('open')"
       type="button"
       class="inline-flex items-center shadow-sm text-white rounded-md px-3 py-2 sm:text-sm encom_pink_bg focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 font-semibold mr-0"
      >
       <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        class="-ml-0.5 mr-1.5 h-5 w-5"
       >
        <path
         d="M2.695 14.763l-1.262 3.154a.5.5 0 00.65.65l3.155-1.262a4 4 0 001.343-.885L17.5 5.5a2.121 2.121 0 00-3-3L3.58 13.42a4 4 0 00-.885 1.343z"
        />
       </svg>

       {{ buttonTitle }}
      </button>
     </span>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
import { SearchIcon } from "@heroicons/vue/outline";
export default {
 name: "PageHeading",
 props: {
  actionButton: {
   type: Object,
   required: false,
  },
  hasMetaData: {
   type: Boolean,
   required: true,
  },
  hasActionButton: {
   type: Boolean,
   required: true,
  },
  hasPermission: {
   type: Boolean,
   required: true,
  },
  hasSearch: {
   type: Boolean,
   required: true,
  },
  buttonTitle: {
   type: String,
   required: false,
  },
  firstButtonTitle: {
   type: String,
   required: false,
  },
  secondButtonTitle: {
   type: String,
   required: false,
  },
  lastButtonTitle: {
   type: String,
   required: false,
  },
  meta: {
   type: Object,
   required: false,
  },
  createdAt: {
   type: String,
   required: false,
  },
  title: {
   type: String,
   required: true,
  },
 },
 emits: ["open", "search"],
 data() {
  return { search: "" };
 },
 components: { SearchIcon },
 methods: {},
 mounted() {},
};
</script>

<style></style>
